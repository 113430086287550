<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs3 text-left>
        <span class="mainHeader">Purchase</span>
      </v-flex>
      <v-flex xs12 py-6 text-left>
        <v-card
          tile
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap class="subhed2" px-4 pt-4>
            <v-flex xs1 pr-2>
              <span>Bill No</span>
            </v-flex>
            <v-flex xs2 pr-2>
              <span>Date</span>
            </v-flex>
            <v-flex xs2 pr-2>
              <span>Supplier name</span>
            </v-flex>
            <v-flex xs2 pr-2>
              <span>Invoice No.</span>
            </v-flex>
            <v-flex xs2 pr-2>
              <span> Suplier Invoice Date</span>
            </v-flex>
          </v-layout>
          <v-layout wrap class="subhed2" px-4 py-1>
            <v-flex xs1 pr-2>
              <v-text-field
                autofocus
                label="Bill.No"
                v-model="Details.bill"
                outlined
                disabled
                dense
                hide-details
              ></v-text-field>
            </v-flex>
            <v-flex xs2 pr-2>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="billDate"
                    label="To Date"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="billDate"
                  no-title
                  @input="(menu2 = false)(getBording())"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs2 pr-2>
              <v-autocomplete
                :items="supplierList"
                v-model="sName"
                label="Select Supplier"
                item-text="supplierName"
                item-value="_id"
                outlined
                hide-details
                dense
              ></v-autocomplete>
            </v-flex>
            <v-flex xs2 pr-2>
              <v-text-field
                autofocus
                label="Invoice.No"
                v-model="Details.invoice"
                outlined
                disabled
                dense
                hide-details
              ></v-text-field>
            </v-flex>
            <v-flex xs2 pr-2>
              <v-menu
                ref="menu3"
                v-model="menu3"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="invoiceDate"
                    label="To Date"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="invoiceDate"
                  no-title
                  @input="menu3 = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs2 pr-2>
              <v-text-field
                autofocus
                label="Thankam Rate"
                v-model="goldRate"
                outlined
                disabled
                dense
                hide-details
              ></v-text-field>
            </v-flex>
            <!-- <v-flex xs2 pr-2>
              <v-select
                :items="CodeList"
                v-model="HSN"
                label="HSN Code"
                item-text="hsnCode"
                item-value="_id"
                outlined
                hide-details
                dense
              ></v-select>
            </v-flex> -->
            <v-spacer></v-spacer>
            <v-flex xs1>
              <v-btn
                dark
                color="#3F053C"
                @click="(addStaff = true)(getCategory())"
              >
                <v-icon>mdi-plus</v-icon> Item
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout px-4 py-4>
            <v-flex xs12 v-if="savedList">
              <v-card outlined class="pa-4" v-if="savedList.length > 0">
                <v-layout wrap>
                  <v-flex xs12>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left subhed"><b>S.No.</b></th>
                            <th class="text-left subhed"><b>Category Id</b></th>
                            <th class="text-left subhed"><b>Item Id</b></th>
                            <th class="text-left subhed"><b>Design Id</b></th>
                            <th class="text-left subhed"><b>Count</b></th>
                            <th class="text-left subhed"><b>Net.Wt</b></th>
                            <!-- <th class="text-left subhed"><b>Gross.Wt </b></th> -->
                            <th class="text-left subhed"><b>Item/Wt</b></th>
                            <th class="text-left subhed"><b>Cost</b></th>
                            <th class="text-left subhed"><b>StonePrice</b></th>
                            <th class="text-left subhed"><b>Stone.Wt</b></th>
                            <th class="text-left subhed"><b>Total(Rs.)</b></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(value, i) in savedList"
                            :key="i"
                            class="table"
                          >
                            <!-- <td>{{ i + 1 }}</td> -->
                            <td>
                              <span>
                                {{ i }}
                              </span>
                            </td>
                            <td>
                              <span v-if="value.designId">{{
                                value.designId.categoryId.name
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.designId">{{
                                value.designId.itemId.name
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.designId">{{
                                value.designId.name
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.count"> {{ value.count }}</span>
                              <span v-else>-</span>
                            </td>
                            <!-- <td>
                              <span v-if="value.grossWeight">{{
                                value.grossWeight
                              }}</span>
                              <span v-else>-</span>
                            </td> -->
                            <td>
                              <span v-if="value.itemPerWeight">{{
                                value.itemPerWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.cost">{{ value.cost }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.stonePrice">{{
                                value.stonePrice
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.stoneWeight">{{
                                value.stoneWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.netWeight">{{
                                value.netWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.taxableAmount">{{
                                value.taxableAmount
                              }}</span>
                              <span v-else>-</span>
                            </td>

                            <!-- <td>
                              <v-icon
                                size="18"
                                style="cursor: pointer"
                                @click="(dialoge = true), (curId = value._id)"
                                >mdi-delete-outline</v-icon
                              >
                            </td> -->
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
                <v-layout wrap v-if="itemz.length > 0">
                  <v-flex mt-3>
                    <v-btn dark color="#3F053C" block @click="submit()"
                      >Save</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout v-if="savedList">
            <v-flex xs6 v-if="savedList.length > 0">
              <v-layout px-4 py-4 wrap>
                <v-flex xs12>
                  <span class="subhed2">Payment Type</span>
                </v-flex>
                <v-flex xs4 pa-2>
                  <v-card color="#EEEEEE" class="px-4 py-2" outlined>
                    <v-layout wrap justify-center>
                      <v-flex xs12 align-self-center>
                        <v-checkbox
                          hide-details
                          v-model="paymentTypes"
                          label="Cash"
                          value="Cash"
                          @change="choosetype()"
                        ></v-checkbox>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex xs4 pa-2>
                  <v-card color="#EEEEEE" class="px-4 py-2" outlined>
                    <v-checkbox
                      hide-details
                      v-model="paymentTypes"
                      label="Thankam"
                      value="Thankam"
                      @change="choosetype()"
                    ></v-checkbox>
                  </v-card>
                </v-flex>
              </v-layout>

              <v-layout wrap v-if="checkvalue == true">
                <v-flex xs6 pa-4>
                  <span>Thankam Code</span>
                  <v-text-field
                    autofocus
                    label="Thankam Code"
                    v-model="thankamcode"
                    outlined
                    disabled
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 pa-4>
                  <span>Batch Code</span>
                  <v-text-field
                    autofocus
                    label="BoardingRate"
                    v-model="thankamDetails.boardingRate"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>

                <v-flex xs6 pa-4>
                  <span>Gold rate</span>
                  <v-text-field
                    autofocus
                    label="thankamWeight"
                    v-model="thankamDetails.thankamWeight"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 pa-4>
                  <span>Gold weight</span>
                  <v-text-field
                    autofocus
                    label="batchCode"
                    v-model="thankamDetails.batchCode"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex pl-2 pb-2 xs2
                  ><v-btn color="#3F053C" dark @click="finalSubmit()"
                    >Generate Bill</v-btn
                  ></v-flex
                >
              </v-layout>
            </v-flex>
            <v-flex xs6 px-4 py-4 align-self-end v-if="savedList.length > 0">
              <v-layout wrap justify-end class="pb-4">
                <v-flex xs9 class="subhed2" text-right>
                  Total Weight (gm) :
                </v-flex>
                <v-flex xs3 class="subhed3" text-center
                  ><span v-if="totalnetweight">{{ totalnetweight }}</span>
                  <span v-else>-</span></v-flex
                >
              </v-layout>
              <v-layout wrap justify-end class="pb-4">
                <v-flex xs9 class="subhed2" text-right>Making Charge : </v-flex>
                <v-flex xs3 class="subhed3" text-center
                  ><span v-if="totalMakingCharge">{{ totalMakingCharge }}</span>
                  <span v-else>-</span></v-flex
                >
              </v-layout>
              <v-layout wrap justify-end class="pb-4">
                <v-flex xs9 class="subhed2" text-right
                  >Total Amount to be paid :
                </v-flex>
                <v-flex xs3 class="subhed3" text-center
                  ><span v-if="finalTotal">{{ finalTotal }}</span>
                  <span v-else>-</span></v-flex
                >
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
        <v-dialog width="400px" v-model="dialoge">
          <v-card width="400px">
            <v-toolbar dark color="grey" dense flat class="body-2">
              <v-toolbar-title> Confirm </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-4 black--text"
              >Are you sure you want to remove this item?
            </v-card-text>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="dialoge = false"
                >Cancel</v-btn
              >
              <v-btn
                color="#690964"
                class="buttons1"
                dark
                @click="deleteStaff()"
                >Delete</v-btn
              >
              <!-- <v-btn
                color="primary"
                class="body-2 font-weight-bold"
                outlined
                @click="itemz.splice(removeitemIndex, 1), (dialoge = false)"
                >OK</v-btn
              > -->
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog width="1000px" v-model="addStaff">
          <v-card width="1000px">
            <v-layout wrap dense flat justify-center class="body-2">
              <v-flex xs12 pt-4 text-center
                ><span style="color: #3f053c; font-size: 25px"
                  >Purchase Details</span
                ></v-flex
              >
              <v-flex xs8 text-center py-4>
                <v-divider></v-divider>
              </v-flex>
            </v-layout>
            <!-- <v-layout wrap>
              <v-flex xs12> -->
            <v-layout wrap px-4 class="text-left" justify-space-around>
              <v-flex xs2 pr-2>
                <span class="subhed3">Category</span>
              </v-flex>
              <v-flex xs2 pr-2>
                <span class="subhed3">Item</span>
              </v-flex>
              <v-flex xs2 pr-2>
                <span class="subhed3">Design</span>
              </v-flex>
              <v-flex xs2 pr-2>
                <span class="subhed3">Count</span>
              </v-flex>
              <v-flex xs2 pr-2>
                <span class="subhed3">Item/weight(gm)</span>
              </v-flex>
            </v-layout>
            <v-layout wrap px-4 pb-4 justify-space-around>
              <v-flex xs2 pr-2>
                <v-select
                  :items="categoryList"
                  v-model="purchaseItem.test"
                  label="Select Category"
                  item-text="name"
                  item-value="_id"
                  @input="getItem()"
                  outlined
                  hide-details
                  dense
                ></v-select>
              </v-flex>
              <v-flex xs2 pr-2>
                <v-select
                  :items="ItemList"
                  v-model="purchaseItem.test2"
                  label="Select Item"
                  item-text="name"
                  item-value="_id"
                  @input="getDesign()"
                  outlined
                  hide-details
                  dense
                ></v-select>
              </v-flex>
              <v-flex xs2 pr-2>
                <v-select
                  :items="designList"
                  v-model="purchaseItem.design"
                  label="Select Item"
                  item-text="name"
                  item-value="_id"
                  outlined
                  hide-details
                  dense
                ></v-select>
              </v-flex>
              <v-flex xs2 pr-2>
                <v-text-field
                  autofocus
                  @input="cal1()"
                  label="Count"
                  v-model="purchaseItem.Count"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs2 pr-2>
                <v-text-field
                  autofocus
                  @input="cal1()"
                  label="Item/weight"
                  v-model="purchaseItem.wt"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap px-4 pb-4 class="text-left" justify-space-around>
              <v-flex xs2 pr-2>
                <span class="subhed3">Stone Price</span>
              </v-flex>
              <v-flex xs2 pr-2>
                <span class="subhed3">Stone Weight</span>
              </v-flex>
              <v-flex xs2 pr-2>
                <span class="subhed3">Net.Wt</span>
              </v-flex>

              <v-flex xs2 pr-2>
                <span class="subhed3">Touch rate(%)</span>
              </v-flex>
              <v-flex xs2 pr-2>
                <span class="subhed3">Marking Charges</span>
              </v-flex>
              <!-- <v-flex xs2 pr-2>
                <span class="subhed3">Making Cost</span>
              </v-flex> -->
            </v-layout>
            <v-layout wrap px-4 justify-space-around>
              <v-flex xs2 pr-2>
                <v-text-field
                  autofocus
                  label="Stone Price"
                  v-model="purchaseItem.StPrice"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs2 pr-2>
                <v-text-field
                  autofocus
                  label="Stone Weight"
                  v-model="purchaseItem.StoneWt"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs2 pr-2>
                <v-text-field
                  autofocus
                  @input="cal2()"
                  label="Net.Wt"
                  disabled
                  v-model="purchaseItem.netwt"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>

              <v-flex xs2 pr-2>
                <v-text-field
                  autofocus
                  @input="cal2()"
                  label="Touch rate(%)"
                  v-model="purchaseItem.cost"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs2 pr-2>
                <v-text-field
                  autofocus
                  disabled
                  label="Marking Charges"
                  v-model="purchaseItem.MarkingC"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>
              <!-- <v-flex xs2 pr-2>
                <v-text-field
                  autofocus
                  label="Making Cost"
                  v-model="makingcost"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex> -->
              <!-- <v-flex xs2 pr-2>
                <v-text-field
                  autofocus
                  label="Bording Rate"
                  v-model="purchaseItem.totalamt"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex> -->
            </v-layout>
            <v-layout wrap px-8 class="text-left">
              <v-flex xs2 pr-2>
                <v-layout wrap>
                  <v-flex xs12 pr-2>
                    <span class="subhed3">Discount Amt.</span>
                  </v-flex>
                  <v-flex xs12 pr-2>
                    <v-text-field
                      autofocus
                      label="Discount Amt."
                      v-model="purchaseItem.discount"
                      outlined
                      @input="cal3()"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs2>
                <v-layout wrap>
                  <v-flex xs12 pr-2>
                    <span class="subhed3">Adjust Touch rate(%)</span>

                  </v-flex>
                  <v-flex xs12 pr-2>
                    <v-text-field
                  autofocus
                  disabled
                  label="Adjust Touch rate(%)"
                  v-model="purchaseItem.adtouchrate"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs3>
                <v-layout wrap>
                  <v-flex xs12 pr-2>
                    <span class="subhed3">Adjust Marking Charges</span>

                  </v-flex>
                  <v-flex xs12 pr-2>
                    <v-text-field
                  autofocus
                  disabled
                  label="Adjust Marking Charges"
                  v-model="purchaseItem.admakingcharge"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <!-- </v-flex>
            </v-layout> -->
            <!-- <v-layout wrap px-4 pt-4>
              <v-flex xs6 px-4 text-left v-if="returndata"
                >Net Wt : {{ returndata.netWeight }} <br />Total : Rs.{{
                  returndata.totalAmount
                }}
              </v-flex>
              <v-flex xs6 text-left>
                <v-btn
                  v-if="check == false"
                  color="#3F053C"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="AddItem()"
                  >Add</v-btn
                ></v-flex
              >
            </v-layout> -->
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>

              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="addStaff = false"
                >Cancel</v-btn
              >
              <!-- :disabled="check == false" -->
              <v-btn
                color="#3F053C"
                class="body-2 font-weight-bold"
                outlined
                @click="addValues()"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      itemz: [],
      totalPurchaseCost: null,
      totalMakingCharge: null,
      username: null,
      name: null,
      address: null,
      phone: null,
      email: null,
      addStaff: false,
      billDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      bill: null,
      purchaseno: null,
      thankamcode: null,
      sName: null,
      thankamrate: null,
      invoiceDate: null,
      makingcost: null,
      savedList: [],
      Details: [],
      designList: [],
      menu3: false,
      menu2: false,
      returndata: null,
      check: false,
      categoryList: [],
      ItemList: [],
      removeitemIndex: null,
      dialoge: false,
      HSN: null,
      supplierList: [],
      totalnetweight: null,
      finalTotal: null,
      goldRate: null,
      tst1: null,
      tst2: null,
      tst3 : null,
      test4 :null,

      CodeList: [],
      purchaseItem: {
        test: null,
        test2: null,
        design: null,
        Count: 0,
        cost: 0,
        netwt: 0,
        totalamt: 0,
        wt: 0,
        MarkingC: 0,
        StPrice: 0,
        StoneWt: 0,
        discount: 0,
        adtouchrate: 0,
        admakingcharge: 0,

      },
      singleitem: {
        categoryId: null,
        itemId: null,
        designId: null,
        // designName: null,
        count: null,
        // grossWeight: null,
        itemPerWeight: null,
        cost: null,
        stonePrice: null,
        stoneWeight: null,
        // boardingRate: null,
        netWeight: null,
        totalAmount: null,
        // hsnCodeId: null,
      },
      paymentTypes: [],
      checkvalue: false,
      purchaseId: null,
      payment: [
        {
          paymentType: null,
          payAmount: null,
        },
      ],
      thankamDetails: [
        {
          thankamId: "63d3a2d42d13b00bbea3a98f",
          boardingRate: null,
          thankamWeight: null,
          batchCode: null,
        },
      ],
    };
  },
  // watch: {
  //  purchaseItem:{

  //     deep:true,
  //     handler: function (){
  //       if(this.purchaseItem.test){
  //     this.getItem();
  //       }
  //        if(this.purchaseItem.test2){
  //     this.getDesign();
  //       }
  //     }
  // }

  // },
  mounted() {
    if (this.billDate) {
      this.getBording();
    }
    this.getBill();
    this.getSupplier();
    this.getHSN();
    this.getData();
    // this.getBording();
  },
  methods: {
    cal1() {
      this.purchaseItem.netwt = this.purchaseItem.Count * this.purchaseItem.wt;
      console.log("purchaseItem.netwt=", this.purchaseItem.netwt);
    },
    cal2() {
      // 
      this.tst1 = (this.purchaseItem.netwt )* this.goldRate;
      this.tst2 = this.purchaseItem.cost * 0.01;
      this.purchaseItem.MarkingC = this.tst1 * this.tst2;
      console.log("tst1", this.tst1);
      console.log("tst2", this.tst2);
      console.log("purchaseItem.MarkingC=", this.purchaseItem.MarkingC);
    },
    cal3() {
      this.purchaseItem.adtouchrate =
        (this.purchaseItem.cost - this.purchaseItem.discount) * 0.01;
      console.log("adtouchrate=", this.purchaseItem.adtouchrate);
      this.purchaseItem.admakingcharge =
        (this.purchaseItem.MarkingC - this.purchaseItem.discount)
      console.log("admakingcharge=", this.purchaseItem.admakingcharge);
      this.total1 = (this.tst1 + this.purchaseItem.admakingcharge)
      if(this.purchaseItem.discount >0)
      {
        this.total2 =this.total1 + this.admakingcharge;
      }
    },
    choosetype() {
      console.log(this.paymentTypes, "array");
      if (this.paymentTypes.includes("Thankam")) {
        this.checkvalue = true;
      } else {
        this.checkvalue = false;
      }
    },
    deleteStaff() {
      axios({
        url: "/purchaseitem/delete",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.dialoge = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getStaff();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //  validateInput() {
    //   if (!this.email) {
    //     this.msg = "Please provide email";
    //     this.showsnackbar = true;
    //     return;
    //   } else if (!this.name) {
    //     this.msg = "Please provide name";
    //     this.showsnackbar = true;
    //     return;
    //   } else if (!this.phone) {
    //     this.msg = "Please provide phone";
    //     this.showsnackbar = true;
    //     return;
    //   } else if (!this.username) {
    //     this.msg = "Please provide username";
    //     this.showsnackbar = true;
    //     return;
    //   }  else if (!this.address) {
    //     this.msg = "Please provide address";
    //     this.showsnackbar = true;
    //     return;
    //   }  else {
    //     this.addAdmin();
    //   }
    // },

    getBill() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/purchase/bill/generate",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        // params: {
        //   page: this.page,
        //   count: 20,
        // },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.Details = response.data;
            this.bill = response.data.bill;
            console.log("billno=", this.bill);
            this.getData();
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    addValues() {
      this.singleitem.categoryId = this.purchaseItem.test;
      this.singleitem.itemId = this.purchaseItem.test2;
      this.singleitem.designId = this.purchaseItem.design;
      // this.singleitem.designName = this.returndata.designName;
      this.singleitem.count = this.purchaseItem.Count;
      this.singleitem.cost = this.purchaseItem.cost;

      this.singleitem.netWeight = this.purchaseItem.netwt;
      this.singleitem.itemPerWeight = this.purchaseItem.wt;
      this.singleitem.cost = this.purchaseItem.MarkingC;
      this.singleitem.stonePrice = this.purchaseItem.StPrice;
      this.singleitem.stoneWeight = this.purchaseItem.StoneWt;
      // this.singleitem.boardingRate = this.purchaseItem.Br;
      // this.singleitem.netWeight = this.returndata.netWeight;
      // this.singleitem.totalAmount = this.purchaseItem.totalamt;
      // this.singleitem.hsnCodeId = this.HSN;
      this.addVariant();
    },
    // addVariant() {
    //   this.itemz.push(JSON.parse(JSON.stringify(this.singleitem)));
    //   console.log("itemz=", this.itemz);
    //   (this.purchaseItem.test = null),
    //     (this.purchaseItem.test2 = null),
    //     (this.purchaseItem.design = null);
    //   this.purchaseItem.Count = null;
    //   this.purchaseItem.netwt = null;
    //   this.purchaseItem.wt = null;
    //   this.purchaseItem.MarkingC = null;
    //   this.purchaseItem.StPrice = null;
    //   this.purchaseItem.StoneWt = null;
    //   this.purchaseItem.totalamt = null;
    //   this.check = false;
    //   this.returndata = [];
    // },
    addVariant() {
      this.itemz.push(JSON.parse(JSON.stringify(this.singleitem)));
      console.log("itemz=", this.itemz);
      (this.purchaseItem.test = null),
        (this.purchaseItem.test2 = null),
        (this.purchaseItem.design = null);
      this.purchaseItem.Count = null;
      this.purchaseItem.netwt = null;
      this.purchaseItem.wt = null;
      this.purchaseItem.MarkingC = null;
      this.purchaseItem.StPrice = null;
      this.purchaseItem.StoneWt = null;
      // this.purchaseItem.totalamt = null;
      this.check = false;
      this.returndata = [];
      axios({
        url: "/pendingitem/add",
        method: "POST",
        data: {
          items: this.itemz,
          billNo: this.Details.bill,
          date: this.billDate,
          supplierId: this.sName,
          invoiceNo: this.Details.invoice,
          invoiceDate: this.invoiceDate,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.addStaff = false;
            this.itemz = [];
            this.purchaseno = response.data.purchaseId;
            console.log("purnum=", this.purchaseno);
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/purchase/item/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          billNo: this.bill,
          purchaseId: this.purchaseno,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.savedList = response.data.data;
            this.totalMakingCharge = response.data.totalMakingCharge;
            this.totalnetweight = response.data.totalnetweight;
            this.finalTotal = response.data.finalTotal;
            // this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getCategory() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/category/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.page,
          count: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.categoryList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getItem() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/item/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          categoryId: this.purchaseItem.test,
          page: this.page,
          count: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.ItemList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getDesign() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/design/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          itemId: this.purchaseItem.test2,
          page: this.page,
          count: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.designList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getBording() {
    console.log("8");
      this.appLoading = true;
      axios({
        url: "/rate/view",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          date: this.billDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.goldRate = response.data.data.goldRate;
        })
        .catch((err) => {
          //   this.ServerError = true;
          console.log(err);
        });
    },
    getHSN() {
      this.appLoading = true;
      axios({
        url: "/hsnCodes/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.currentPage,
          limit: 50,
          searchKeyword: "",
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.CodeList = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / 50);
        })
        .catch((err) => {
          //   this.ServerError = true;
          console.log(err);
        });
    },
    getSupplier() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/supplier/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.page,
          count: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.supplierList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    AddItem() {
      axios({
        url: "/individual/itemcalculation",
        method: "POST",
        data: {
          designId: this.purchaseItem.design,
          count: this.purchaseItem.Count,
          netWeight: this.purchaseItem.netwt,
          itemPerWeight: this.purchaseItem.wt,
          cost: this.purchaseItem.MarkingC,
          stonePrice: this.purchaseItem.StPrice,
          stoneWeight: this.purchaseItem.StoneWt,
          boardingRate: this.purchaseItem.Br,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;

            this.check = true;
            console.log("check=", this.check);
            this.returndata = response.data;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    submit() {
      axios({
        method: "post",
        url: "/purchase/settle/later",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          billNo: this.Details.bill,
          purchaseDate: this.billDate,
          supplierId: this.sName,
          invoiceNo: this.Details.invoice,
          items: this.itemz,
          invoiceDate: this.invoiceDate,
        },
      })
        .then((response) => {
          if (response.data.status) {
            // this.uploadId = response.data.bill._id;
            // if (this.bills.length > 0) this.uploadBills(this.uploadId);
            this.msg = response.data.msg;
            this.showsnackbar = true;
            // this.$router.go(-1);
            this.totalMakingCharge = response.data.totalMakingCharge;
            this.totalPurchaseCost = response.data.totalPurchaseCost;
            this.purchaseId = response.data._id;
            // this.Details.bill = null;
            // this.billNo = null;
            // this.billDate = null;
            // this.sName = null;
            // this.Details.invoice = null;
            // this.invoiceDate = null;
            // this.itemz = [];
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    finalSubmit() {
      axios({
        method: "post",
        url: "/purchase/payment",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          purchaseId: this.purchaseno,
          paymentType: this.paymentTypes,
          payAmount: this.finalTotal,
          thankamDetails: this.thankamDetails,
        },
      })
        .then((response) => {
          if (response.data.status) {
            // this.uploadId = response.data.bill._id;
            // if (this.bills.length > 0) this.uploadBills(this.uploadId);
            this.msg = response.data.msg;
            this.showsnackbar = true;
            // this.$router.go(-1);
            this.totalMakingCharge = response.data.totalMakingCharge;
            this.totalPurchaseCost = response.data.totalPurchaseCost;
            this.purchaseId = response.data.id;
            // this.Details.bill = null;
            // this.billNo = null;
            // this.billDate = null;
            // this.sName = null;
            // this.Details.invoice = null;
            // this.invoiceDate = null;
            // this.itemz = [];
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
